import React, { CSSProperties, FC } from "react";
import { Spin } from "antd";
import stl from "./Loading.module.scss";
import LoadingLinearProgress from "../LoadingLlinearProgress/LoadingLinearProgress";

interface Props {
  style?: CSSProperties;
  addLoadingLinearProgress?: true;
}
const Loading: FC<Props> = ({ style, addLoadingLinearProgress }) => {
  return (
    <>
      {addLoadingLinearProgress && <LoadingLinearProgress />}
      <div className={stl.block}>
        <Spin />
      </div>
    </>
  );
};

export default Loading;
