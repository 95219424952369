type AsyncMap = <T, R = T>(
  a: T[] | null | undefined,
  fn: (v: T, i: number) => Promise<R>
) => Promise<R[]>;

export const asyncMap: AsyncMap = async (dataArr, fn) => {
  if (!Array.isArray(dataArr)) return new Promise((resolve) => resolve([]));
  const res = [];
  for (let i = 0; i < dataArr.length; i++) {
    res.push(await fn(dataArr[i], i));
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
  return res;
};

type AsyncReduce = <T, R = T>(
  a: T[],
  fn: (a: R, v: T, i: number) => Promise<R>
) => Promise<R[]>;

export const asyncReduce: AsyncReduce = async (dataArr, fn) => {
  let res: any = {};
  let i = 0;
  for (const item of dataArr) {
    i++;
    res = await fn(res, item, i);
  }
  return res;
};
