import { Rule } from "rc-field-form/lib/interface";
import { toNumber } from "./toNumber";

// created these validators because { max: 100 } been giving an error
// "... is not a valid undefined" where "undefined" should've been type
// but even with specified type="number" error was occuring
export const validators = {
  max: (max: number, message: string): Rule => {
    return () => ({
      validator(_: any, value: number | string) {
        if (!value || toNumber(value) <= max) {
          return Promise.resolve();
        }
        return Promise.reject(message);
      },
    });
  },
  min: (min: number, message: string): Rule => {
    return () => ({
      validator(_: any, value: number | string) {
        if (!value || toNumber(value) > min) {
          return Promise.resolve();
        }
        return Promise.reject(message);
      },
    });
  },
};
