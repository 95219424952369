import React, { FC, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "./components/Loading/Loading";
import pagesRoutes from "./shared/pageRoutes";

const App: FC = () => (
  <Suspense fallback={Loading({})}>
    <Switch>
      {pagesRoutes.map(({ path, exact, component }) => (
        <Route
          key={path?.toString()}
          path={path}
          exact={exact}
          component={component}
        />
      ))}
    </Switch>
  </Suspense>
);

export default App;

if (process.env.NODE_ENV === "development") {
  document.addEventListener("keydown", (e) => {
    if (e.key === "F9") {
      debugger;
    }
  });
}
