import React, { CSSProperties, FC } from "react";
import stl from "./LoadingLinearProgress.module.scss";
import { Progress } from "antd";
import { $currentLocationData } from "../../store/shared";
import { useStore } from "effector-react";

interface Props {
  style?: CSSProperties;
}
const LoadingLinearProgress: FC<Props> = ({ style }) => {
  const currentLocationData = useStore($currentLocationData);
  return (
    <Progress
      className={stl.linear}
      trailColor={currentLocationData?.color as string}
      showInfo={false}
    />
  );
};

export default LoadingLinearProgress;
