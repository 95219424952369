import { notification } from "antd";

export const copyToClipboard = (str: string) => {
  if (str && str.length > 0) {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notification.success({ message: "Copied to clipboard." });
  }
};
