import { createEffect, createEvent, createStore } from "effector";
import http, { objToFormData } from "../shared/http";
import { notification } from "antd";

const path = "booking-engine-settings/1";

interface BookingEngineSettings {
  background_image?: string | null;
  contact_url: string; // "https://www.wima.be/nl/contact"
  currency: string; //"EUR"
  custom_js_code: string | null;
  default_language: string; //"en"
  email: string | null; // "wima.schelle@wima.be"
  facebook: string | null; // "https://www.facebook.com/wimadinnerbowling/"
  id: number;
  instagram: string | null; //"https://www.instagram.com/wimabowlingschelle/"
  linkedin: string | null;
  phone: string | null;
  logo?: string | null; // "https://dev.booktivity.io/storage/tenancy/tenants/9419f20366e6462b87871fc6107eaa7c/media/89/telegram-cloud-photo-size-2-5361902232947240348-x.png"
  logo_white?: string | null; // "https://dev.booktivity.io/storage/tenancy/tenants/9419f20366e6462b87871fc6107eaa7c/media/89/telegram-cloud-photo-size-2-5361902232947240348-x.png"
  primary_color: string | null; // "#f97640"
  privacy_policy_url: string | null; // "https://www.wima.be/nl/privacy"
  proclaimer_url: string | null; // "https://www.wima.be/nl/proclaimer"
  seo_description: string | null; // "XZD-XZD"
  seo_keywords: string | null; // "XZKW-XZKW"
  seo_title: string | null; // "XZT-XZT"
  terms_and_conditions_url: string | null; // "https://www.wima.be/nl/algemene-voorwaarden"
  twitter: string | null; // null
  website_url: string | null; // "https://wima.be"
  important_announcement_title: string | null;
  important_announcement_text: string | null;
}

export const $bookingEngineSettings = createStore<BookingEngineSettings | null>(
  null
);
const setBookingEngineSettings = createEvent<BookingEngineSettings | null>();
export const $bookingEngineSettingsPending = createStore<boolean>(false);
const setBookingEngineSettingsPending = createEvent<boolean>();

export const bookingEngineSettingsApi = {
  read: createEffect<undefined, BookingEngineSettings>("", {
    handler: async () => {
      const response = await http.get<{ data: BookingEngineSettings }>(path);
      return response.data.data;
    },
  }),
  updateBackgroundImage: createEffect<string, BookingEngineSettings>("", {
    handler: async (file) => {
      setBookingEngineSettingsPending(true);

      const fd = objToFormData({
        background_image: file,
        _method: "PUT",
      });

      const response = await http.post<{
        data: BookingEngineSettings;
        message: string;
      }>(path, fd, { headers: { "Content-Type": "multipart/form-data" } });

      setBookingEngineSettingsPending(false);

      notification.success({ message: response.data.message });
      setBookingEngineSettings(response.data.data);

      return response.data.data;
    },
  }),
  updateLogo: createEffect<string, BookingEngineSettings>("", {
    handler: async (file) => {
      setBookingEngineSettingsPending(true);

      const fd = objToFormData({
        logo: file,
        _method: "PUT",
      });

      const response = await http.post<{
        data: BookingEngineSettings;
        message: string;
      }>(path, fd, { headers: { "Content-Type": "multipart/form-data" } });

      setBookingEngineSettingsPending(false);

      notification.success({ message: response.data.message });
      setBookingEngineSettings(response.data.data);

      return response.data.data;
    },
  }),
  update: createEffect<BookingEngineSettings, BookingEngineSettings>("", {
    handler: async (data) => {
      setBookingEngineSettingsPending(true);

      const dataCloned = { ...data };

      delete dataCloned.background_image;
      delete dataCloned.logo;

      const response = await http.put<{
        data: BookingEngineSettings;
        message: string;
      }>(path, dataCloned);

      setBookingEngineSettingsPending(false);

      notification.success({ message: response.data.message });
      setBookingEngineSettings(response.data.data);

      return response.data.data;
    },
  }),
};

$bookingEngineSettings.on(bookingEngineSettingsApi.read.doneData, (s, v) => v);
$bookingEngineSettings.on(setBookingEngineSettings, (s, v) => v);
$bookingEngineSettingsPending.on(setBookingEngineSettingsPending, (s, v) => v);
