import { getRatio, getOriginBeforeSubstrRatio, getPercent } from "./utils";
import { calcFieldTotals } from "./calcFieldTotals";
import { isNaN } from "lodash";
import money from "currency.js";

// TODO: overthink it and refactor in a hard way
export const calcReservationPackage = (
  rawInitials: {
    price: number;
    quantity: number;
    discount: number;
    vat: number;
  }[],
  price: number
): {
  total: number;
  totalDiscount: number;
  totalGrand: number;
  totalTaxes: number;
} => {
  const calculatedInitials = rawInitials.map((i) => {
    // return calcFieldTotals(i.price, i.quantity, i.discount, i.vat);
    return calcFieldTotals(i.price, i.quantity, 0, i.vat, true);
  });

  const summedInitials = calculatedInitials.reduce(
    (acc, i) => {
      return {
        total: acc.total.add(i.total),
        totalDiscount: acc.totalDiscount.add(i.totalDiscount),
        totalGrand: acc.totalGrand.add(i.totalGrand),
        totalTaxes: acc.totalTaxes.add(i.totalTaxes),
      };
    },
    {
      total: money(0),
      totalGrand: money(0),
      totalDiscount: money(0),
      totalTaxes: money(0),
    }
  );

  const vatPackagePercent = getRatio(
    summedInitials.totalGrand.value,
    summedInitials.totalTaxes.value
  );

  const priceWithoutVat =
    summedInitials.totalGrand.value - summedInitials.totalTaxes.value;
  const priceWithoutDiscount =
    priceWithoutVat + summedInitials.totalDiscount.value;

  const discountPackagePercent = getRatio(
    priceWithoutDiscount,
    summedInitials.totalDiscount.value
  );

  const vatAmount = getPercent(price, vatPackagePercent);
  const newPriceWithoutVat = price - vatAmount;

  const newPrice = getOriginBeforeSubstrRatio(
    newPriceWithoutVat,
    discountPackagePercent
  );

  const discountAmount = newPrice - newPriceWithoutVat;

  return {
    total: isNaN(newPrice) ? 0 : parseFloat(newPrice.toFixed(2)),
    totalTaxes: isNaN(vatAmount) ? 0 : parseFloat(vatAmount.toFixed(2)),
    totalDiscount: isNaN(discountAmount)
      ? 0
      : parseFloat(discountAmount.toFixed(2)),
    totalGrand: parseFloat(price.toFixed(2)),
  };
};
