import React, { FC } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./shared/i18n";
import { ConfigProvider } from "antd";
import validateMessages from "./shared/messages";
import locale from "antd/lib/locale-provider/en_GB"; // responsible for translations
import { IconContext } from "react-icons";
import { Router } from "react-router-dom";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import { history } from "./index";
import "moment/locale/en-gb"; // responsible for date configuration (first day of week, daylight saving time, etc.)
import moment from "moment-timezone";

moment.tz.setDefault("Europe/Brussels"); // global for Summertime DST

const Providers: FC = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider form={{ validateMessages }} locale={locale}>
        <IconContext.Provider value={{ className: "anticon" }}>
          <Router history={history}>
            <DndProvider backend={Backend}>{children}</DndProvider>
          </Router>
        </IconContext.Provider>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default Providers;
