import { parse, stringify } from "qs";

export const qs = {
  parse,
  stringify: (obj: object | undefined | null, insertQ = true): string => {
    if (!obj) return "";
    const q = insertQ ? "?" : "";
    return q + stringify(obj, { arrayFormat: "comma", encode: false });
  },
};
