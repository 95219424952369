import axios from "axios";
import { notification } from "antd";
import { history } from "../index";
import { isString } from "lodash";
import queryString from "qs";
import { $auth } from "../store/auth";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  baseURL: process.env.REACT_APP_DEV_API_URL,
  // baseURL: "https://test.booktivity.io/api/",
  // baseURL: "https://wima.booktivity.io/api/", // to check production errors
  // baseURL: "https://local.booktivity.io/api/", // for backenders local work
});

export const objToFormData = (obj: any) => {
  const fd = new FormData();
  Object.keys(obj).forEach((key) => {
    fd.set(key, obj[key]);
  });
  return fd;
};

const requestStart = (request: any): any => {
  const auth = $auth.getState();
  if (auth.isLoggedIn) {
    request.headers["Authorization"] = `Bearer ${auth.token}`;
  }
  return request;
};
// TODO: set typings
const requestError = (error: any): Promise<any> => Promise.reject({ ...error });

axiosInstance.interceptors.request.use(
  (request) => requestStart(request),
  (error) => requestError(error)
);

const responseSuccess = (response: any): any => response;

const responseError = (error: any): any => {
  const err = Promise.reject({ ...error });
  err.catch((err) => {
    const doErr = notification.error;
    if (err.response) {
      const data = err.response.data;
      if (
        err.response.status === 400 &&
        err.response.data.errors === "Unauthenticated."
      ) {
        history.push("/login");
        return;
      }
      if (err.response.status === 401) {
        if (err.response.data.message) {
          doErr({ message: err.response.data.message });
        }
      } else if (err.response.status === 500) {
        doErr({
          message: "Server error!",
          description: err.response.data.message,
        });
      } /*else if (err.response.status === 404) {
        doErr({ message: "Not found!" });
      }*/ else if (
        err.response.data.message
      ) {
        doErr({ message: data.message });
      } else if (
        err.response.data.errors &&
        isString(err.response.data.errors)
      ) {
        doErr({ message: data.errors });
      }
    } else {
      doErr({
        message: "Some error occured!",
        description:
          "This may happen if your Internet connection is down. Or if server is brawling.",
        duration: 3,
      });
    }
  });
  return err;
};

axiosInstance.interceptors.response.use(
  (response) => responseSuccess(response),
  (error) => responseError(error)
);

export default axiosInstance;

export const qs = (obj: object | undefined | null) =>
  obj
    ? "?" +
      queryString.stringify(obj, { arrayFormat: "brackets", encode: false })
    : "";
