export const substrPercent = (amount: number, percent: number): number => {
  return amount * (1 - percent / 100);
};

export const addPercent = (amount: number, percent: number): number => {
  return amount * (1 + percent / 100);
};

export const getPercent = (val: number, percent: number): number => {
  return val * (percent / 100);
};

export const getRatio = (val1: number, val2: number): number => {
  return (val2 / val1) * 100;
};

export const getOriginBeforeSubstrRatio = (
  amount: number,
  percent: number
): number => {
  return amount / (1 - percent / 100);
};
