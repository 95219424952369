import React, { FC } from "react";
import { history } from "../index";
import { $auth } from "../store/auth";
import { useStore } from "effector-react";

interface Props {
  (component: FC): FC;
}
const authGuard: Props = (WrappedComponent) => {
  const AuthGuard: FC = () => {
    const auth = useStore($auth);

    if (!auth.isLoggedIn) {
      history.push("/login");
      return null;
    }

    return <WrappedComponent />;
  };

  return AuthGuard;
};

export default authGuard;
