import { toNumber } from "../toNumber";

type TCalcFieldToTotals = (
  price: number | string,
  quantity: number | string,
  discount?: number | string,
  vat?: number | string,
  shouldNotRound?: boolean
) => {
  total: number;
  totalTaxes: number;
  totalDiscount: number;
  totalGrand: number;
};
export const calcFieldTotals: TCalcFieldToTotals = (
  price,
  quantity,
  discount,
  vat,
  shouldNotRound
) => {
  const nPrice = toNumber(price);
  const nQuantity = toNumber(quantity);
  const nDiscount = toNumber(discount);
  const nVat = toNumber(vat);

  const nTotal = nPrice * nQuantity;

  let nTotalGrand = nTotal;

  const nTotalDiscount = nTotalGrand * (nDiscount / 100);
  nTotalGrand = nTotalGrand - nTotalDiscount;

  const nTotalTaxes = nTotalGrand * (nVat / 100);
  nTotalGrand = nTotalGrand + nTotalTaxes;

  if (shouldNotRound) {
    return {
      total: nTotal,
      totalGrand: nTotalGrand,
      totalDiscount: nTotalDiscount,
      totalTaxes: nTotalTaxes,
    };
  }
  return {
    total: parseFloat(nTotal.toFixed(2)),
    totalGrand: parseFloat(nTotalGrand.toFixed(2)),
    totalDiscount: parseFloat(nTotalDiscount.toFixed(2)),
    totalTaxes: parseFloat(nTotalTaxes.toFixed(2)),
  };
};
