import { RouteProps } from "react-router-dom";
import { lazy } from "react";
import authGuard from "./authGuard";

const PaymentSuccess = lazy(
  () => import("../pages/PaymentSuccess/PaymentSuccess")
);
const Page404 = lazy(() => import("../pages/Page404/Page404"));
const Page500 = lazy(() => import("../pages/Page500/Page500"));
const LogIn = lazy(() => import("../pages/LogIn/LogIn"));
const DefaultLayout = lazy(() => import("../layouts/Default/Default"));

const routes: RouteProps[] = [
  {
    path: "/payment-success",
    exact: true,
    component: PaymentSuccess,
  },
  { path: "/404", exact: true, component: Page404 },
  { path: "/500", exact: true, component: Page500 },
  { path: "/login", exact: true, component: LogIn },
  {
    path: "/",
    exact: false,
    component: authGuard(DefaultLayout),
  },
];

export default routes;
