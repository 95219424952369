import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./shared/notification";
import "./shared/sentry";
import "./index.scss";
import moment from "moment";
import "moment/locale/nl-be";
import Providers from "./Providers";
import { bookingEngineSettingsApi } from "./store/bookingEngineSettings";

moment.locale("en-gb");

export const history = createBrowserHistory();
bookingEngineSettingsApi.read(undefined);

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
