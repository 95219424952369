import { createApi, createStore, createEffect } from "effector";
import { history } from "../";
import http from "../shared/http";
import { notification } from "antd";

const LS_TOKEN_KEY = "access_token";

const initialToken = localStorage.getItem(LS_TOKEN_KEY);

export const $auth = createStore<{
  pending: boolean;
  errored: boolean;
  error: string[] | null;

  isLoggedIn: boolean;
  token: string | null;
}>({
  pending: false,

  errored: false,
  error: null,

  isLoggedIn: !!initialToken,
  token: initialToken,
});

export const authApi = createApi($auth, {
  setPending: (store, v: boolean) => {
    return {
      ...store,
      pending: v,
    };
  },
  setToken: (store, v: string | null) => {
    if (v) localStorage.setItem(LS_TOKEN_KEY, v);
    return {
      ...store,
      isLoggedIn: !!v,
      token: v,
    };
  },
  setErrors: (store, v: string[] | null) => {
    return {
      ...store,
      errored: !!v,
      error: v,
    };
  },
});

export const logInFx = createEffect("Log in", {
  handler: async (reqData: { email: string; password: string }) => {
    authApi.setPending(true);
    authApi.setErrors(null);
    try {
      const { data } = await http.post<{
        access_token: null | string;
        expires_at: null | string;
        token_type: null | string;
      }>("/login", reqData);
      authApi.setToken(data.access_token);
      notification.success({ message: "Success" });
      setTimeout(() => {
        history.push("/");
      }, 500);
    } catch (e) {
      authApi.setErrors(e);
    } finally {
      authApi.setPending(false);
    }
  },
});

export const logOutFx = createEffect("Log out", {
  handler: () => {
    authApi.setToken(null);
    localStorage.removeItem(LS_TOKEN_KEY);
    history.push("/login");
  },
});
