import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init(
    {
      lng: "en",
      fallbackLng: "en",

      ns: [
        "translation",
        "appModules",
        "appModulesCategories",
        "ff",
        "crudCommon",
      ],
      defaultNS: "translation",

      debug: false,

      react: { wait: true },
    },
    (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
    }
  );

export default i18n;
