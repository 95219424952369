import { isNumber, isNaN } from "lodash";
import { isPresent } from "./isPresent";

export const toNumber = (
  val: number | string | undefined | null,
  fallBackNumber?: number
): number => {
  const fb = fallBackNumber ?? 0;
  if (!isPresent(val)) return fb;

  if (isNumber(val)) return val;

  const parsed = parseFloat(val);
  if (!isNaN(parsed)) return parsed;

  return fb;
};
