import { createEffect, createEvent, createStore } from "effector";
import http, { qs } from "../shared/http";
import { Location } from "../storeCruds/models";
import { isPresent, toNumber } from "../utils";
import { GeneralReport } from "../modules/Reports/General/types";
import { resetAll } from "../modules/Reservations/Items/Packages/Form/store/shared/resetAll";
import { authApi, logOutFx } from "./auth";

export const $currency = createStore<string>("€");
export const setCurrency = createEvent<string>("set currency");
$currency.on(setCurrency, (state, payload) => payload);

const LS_LOCATION_KEY = "location_id";

const defaultLocationStr: string | null = localStorage.getItem(LS_LOCATION_KEY);
const defaultLocation: number | null = isPresent(defaultLocationStr)
  ? toNumber(defaultLocationStr)
  : defaultLocationStr;

export const $currentLocation = createStore<number | null>(defaultLocation);
export const setCurrentLocation = createEvent<number>(
  "set user current location"
);
$currentLocation.on(setCurrentLocation, (state, payload) => {
  localStorage.setItem(LS_LOCATION_KEY, payload + "");
  return payload;
});

export const $currentLocationData = createStore<Location | null>(null);
export const setCurrentLocationData = createEvent<Location | null>(
  "set user current location data"
);
$currentLocationData.on(setCurrentLocationData, (state, payload) => payload);

const fetchSystemPersonTypeId = createEffect(async (location: number) => {
  const { data: allPersonTypes } = await http.get<{
    data: { is_system: boolean; id: number }[];
  }>(`person-types?page=1&per_page=99999&location_id=${location}&lang=en`);

  const systemPersonType = allPersonTypes?.data.find(
    ({ is_system }) => is_system
  );
  return systemPersonType?.id ?? 0;
});

export const $packageQuantity = createStore<number>(0).reset(resetAll);
export const setPackageQuantity = createEvent<number>();
$packageQuantity.on(setPackageQuantity, (_, v) => v);

export const $systemPersonTypeId = createStore<number>(0).on(
  fetchSystemPersonTypeId.doneData,
  (_, data) => data
);

$currentLocation.watch(async (id) => {
  if (isPresent(id)) {
    const { data } = await http.get<{ data: Location }>(`locations/${id}`);
    setCurrentLocationData(data.data);
    await fetchSystemPersonTypeId(id);
  } else {
    setCurrentLocationData(null);
  }
});

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
$currentLocationData.watch((data) => {
  if (data && data.color) {
    const color = hexToRgb(data.color);
    if (color) {
      document.body.style.setProperty(
        "--c-primary_rgb",
        [color.r, color.g, color.b].join(", ")
      );
    } else {
      document.body.style.removeProperty("--c-primary_rgb");
    }
  } else {
    document.body.style.removeProperty("--c-primary_rgb");
  }
});

type RoleRes = {
  created_at: string;
  guard_name: "api";
  id: number;
  name: Role;
  pivot: {
    model_id: number;
    role_id: number;
    model_type: string;
  };
  updated_at: string;
};
export enum Role {
  admin = "admin",
  manager = "manager",
}
export const $role = createStore<Role | null>(null);
export const setRole = createEvent<Role | null>("set user role");
$role.on(setRole, (state, payload) => payload);

type TMe = {
  id: number;
  name: null;
  last_name: null;
  email: null;
  role: RoleRes[] | null;
  phone: null;
  locations: Location[];
  created_at: null;
  updated_at: null;
  last_login_at: null;
  avatar: null;
};

export const $me = createStore<TMe | null>(null);
export const readMeFx = createEffect({
  name: "read me",
  handler: async () => {
    let data: TMe | null = null;
    try {
      const response = await http.get<{ data: TMe }>("/me");
      data = response.data.data;
      if (!data.id) {
        await logOutFx();
        return undefined;
      }
    } catch (e) {
      authApi.setErrors(e);
      await logOutFx();
      return undefined;
    }
    const currentLocation = $currentLocation.getState();
    if (
      !isPresent(currentLocation) ||
      !data?.locations.some((v) => v.id === currentLocation)
    ) {
      setCurrentLocation(data?.locations[0]?.id ?? 0);
    }
    setRole(data?.role?.[0].name ?? null);
    return data;
  },
});
$me.on(readMeFx.doneData, (state, payload) => payload);

export const readGeneralReportFx = createEffect("read detailed report", {
  handler: async (params: {
    [k: string]: any;
  }): Promise<GeneralReport[] | null> => {
    const {
      data: { data },
    } = await http.get<{ data: GeneralReport[] }>(`reports/daily${qs(params)}`);

    if (data.length === 0) {
      return null;
    }
    return data;
  },
});
